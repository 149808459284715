import { NftMetadata } from "@/types/nft"

import { PackageNftCard } from "@/components/packages/package-nft-card"

interface PackagesListProps {
  packages: NftMetadata[]
  className?: string
}

export const PackagesList = ({ packages }: PackagesListProps) => {
  return (
    <ul className="grid grid-cols-2 gap-3">
      {packages.map((pkg, index) => {
        return (
          <li key={index}>
            <PackageNftCard metadata={pkg} />
          </li>
        )
      })}
    </ul>
  )
}
