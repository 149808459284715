import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
} from "react"
import { LinkProps, NavLink } from "react-router-dom"

import { cn } from "@/utils/tw-utils"
import { Badge } from "@/components/ui/badge"
import { Button, ButtonProps, buttonVariants } from "@/components/ui/button"

type MenuProps = React.ComponentPropsWithoutRef<"ul"> & PropsWithChildren

const Menu = ({ children, className }: MenuProps) => {
  return (
    <ul className={cn("group flex items-center gap-1", className)}>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) return child

        return (
          <li key={child.key} className="w-full">
            {cloneElement(child, { ...child.props })}
          </li>
        )
      })}
    </ul>
  )
}
Menu.displayName = "Menu"

type MenuItemProps = {
  title: string
  icon?: React.ComponentType<React.ComponentPropsWithoutRef<"svg">>
  active?: boolean
  pulse?: boolean
  badge?: React.ReactNode
}

const MenuButton = ({
  title,
  icon: Icon,
  badge,
  active = false,
  pulse = false,
  className,
  ...props
}: MenuItemProps & ButtonProps) => {
  return (
    <Button
      variant="ghost"
      className={cn(
        "gap-2 text-foreground/60 focus-visible:text-foreground [&_[data-pulse]]:bg-primary",
        {
          "bg-frozen/40 text-foreground backdrop-blur transition-colors [&_svg]:text-primary [&_svg]:transition-colors ":
            active,
        },
        className
      )}
      {...props}
    >
      {Icon ? (
        <span className="relative flex h-4 w-4">
          <span
            data-pulse="pulse"
            className={cn(
              "absolute inline-flex h-full w-full rounded-full opacity-0",
              {
                "animate-ping opacity-75": pulse,
              }
            )}
          />
          <Icon className="h-4 w-4" />
        </span>
      ) : null}
      {title}
      {badge && (
        <Badge
          className="ml-auto border border-foreground text-foreground opacity-75"
          variant="outline"
        >
          {badge}
        </Badge>
      )}
    </Button>
  )
}
MenuButton.className = "MenuButton"

const MenuLink = ({
  to,
  title,
  target,
  pulse,
  icon: Icon,
  className,
  ...props
}: MenuItemProps & LinkProps) => {
  return (
    <NavLink
      to={to}
      target={target}
      className={({ isActive }) => {
        return cn(
          buttonVariants({
            variant: "ghost",
            size: "default",
            className: cn(
              "gap-2 text-foreground/60 focus-visible:text-foreground [&_[data-pulse]]:bg-primary",
              {
                "bg-frozen/40 text-foreground backdrop-blur transition-colors [&_svg]:text-primary [&_svg]:transition-colors ":
                  isActive,
              },
              className
            ),
          })
        )
      }}
      {...props}
    >
      {Icon ? (
        <span className="relative flex h-4 w-4">
          <span
            data-pulse="pulse"
            className={cn(
              "absolute inline-flex h-full w-full rounded-full opacity-0",
              {
                "animate-ping opacity-75": pulse,
              }
            )}
          />
          <Icon className="h-4 w-4" />
        </span>
      ) : null}

      {title}
    </NavLink>
  )
}
MenuLink.displayName = "MenuLink"

export { Menu, MenuLink, MenuButton }
