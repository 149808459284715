import { PropsWithChildren } from "react"
import { motion } from "framer-motion"

import { Package } from "@/types/package"

import { cn } from "@/utils/tw-utils"
import { Badge } from "@/components/ui/badge"
import { Spinner } from "@/components/ui/spinner"
import { TiltEffect } from "@/components/ui/tilt-effect"

interface PackageCardProps extends PropsWithChildren {
  pkg: Package
  loading?: boolean
  className?: string
}

export const PackageCard = ({
  pkg,
  loading,
  children,
  className,
}: PackageCardProps) => {
  return (
    <div>
      <TiltEffect
        className={cn(
          className,
          "relative border-[2px] border-secondary bg-background/75"
        )}
      >
        <motion.div
          initial={{
            translateZ: 37.5,
          }}
          whileHover={{
            translateZ: 37.5,
          }}
          transition={{
            duration: 0.5,
            ease: "easeOut",
          }}
          className=" px-3 py-10"
        >
          <div className="aspect-[1156/1600] bg-background shadow-xl shadow-secondary/10">
            <img
              src={pkg.preview.source}
              alt={pkg.name}
              className={cn("block w-full transition-opacity", {
                "opacity-60": loading,
              })}
            />
          </div>
        </motion.div>

        <div
          className="absolute left-3 right-3 top-2.5 flex items-center justify-between"
          style={{
            transform: "translateZ(0px)",
          }}
        >
          <h6 className="text-sm uppercase text-secondary">{pkg.name}</h6>

          <Badge variant="outline">{pkg.cardsQuantity} cards</Badge>
        </div>

        <div
          className="absolute bottom-[18px] left-4 right-4 flex items-center justify-center"
          style={{
            transform: "translateZ(75px)",
          }}
        >
          {children}
        </div>

        {loading && (
          <div
            className="absolute left-1/2 top-1/2 z-10"
            style={{
              transform: "translateZ(75px) translateX(-50%) translateY(-50%)",
            }}
          >
            <span className="animate-in fade-in-0">
              <Spinner className="h-8 w-8 text-secondary" />
            </span>
          </div>
        )}
      </TiltEffect>
    </div>
  )
}
