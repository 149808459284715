import { ComponentPropsWithoutRef } from "react"

import { cn } from "@/utils/tw-utils"
import { formatBalance } from "@/utils/venom-utils"
import { VenomIcon, VenomIconProps } from "@/components/icons/venom-icon"

type BalanceProps = Omit<ComponentPropsWithoutRef<"span">, "children"> & {
  value: number | string
  _icon?: VenomIconProps
}

const Balance = ({ value, className, _icon, ...props }: BalanceProps) => {
  const { className: iconClassName, ...iconProps } = _icon ?? {}

  return (
    <span
      className={cn("inline-flex items-center gap-1.5", className)}
      {...props}
    >
      <VenomIcon
        className={cn("h-4 w-4 text-primary", iconClassName)}
        variant="outline"
        {...iconProps}
      />
      {formatBalance(value)}
    </span>
  )
}
Balance.displayName = "Balance"

export { Balance }
