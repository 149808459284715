import React, { useEffect } from "react"
import { IParticlesOptions, MoveDirection, OutMode } from "@tsparticles/engine"
import { loadStarsPreset } from "@tsparticles/preset-stars"
import Particles, { initParticlesEngine } from "@tsparticles/react"

const options: Partial<IParticlesOptions> = {
  background: {
    color: "transparent",
  },
  particles: {
    number: {
      value: 150,
    },
    move: {
      direction: MoveDirection.none,
      enable: true,
      outModes: {
        default: OutMode.none,
      },
      random: true,
      speed: 0.5,
      straight: false,
    },
    opacity: {
      animation: {
        enable: true,
        speed: 1,
        sync: false,
      },
      value: { min: 0.1, max: 0.5 },
    },
    size: {
      value: { min: 0.25, max: 2.5 },
    },
  },
}

const FireflyParticles = () => {
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadStarsPreset(engine)
    })
  }, [])

  return <Particles id="tsparticles" options={options} />
}

export { FireflyParticles }
