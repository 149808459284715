import { Address } from "everscale-inpage-provider"
import { useQuery, UseQueryResult } from "react-query"

import { NftMetadata } from "@/types/nft"

import { getOwnedCollectionNfts } from "@/utils/nft-utils"
import { useVenom } from "@/providers/venom-provider"

interface UseCollectionNftsProps {
  walletAddress?: Address | string
  collectionAddress: Address | string
  enabled?: boolean
  onSuccess?: (data: unknown) => void
  onError?: (error: Error) => void
}

export const useOwnedCollectionNfts = ({
  walletAddress,
  collectionAddress,
  onSuccess,
  onError,
  enabled = true,
}: UseCollectionNftsProps) => {
  const { venomProvider } = useVenom()

  const result = useQuery({
    queryKey: ["ownedCollectionNfts", walletAddress, collectionAddress],
    queryFn: async () => {
      if (!venomProvider) {
        throw new Error("Venom provider is not available now")
      }

      if (!walletAddress) {
        throw new Error("Wallet address is not provided")
      }

      return await getOwnedCollectionNfts(
        venomProvider,
        walletAddress.toString(),
        collectionAddress.toString()
      )
    },
    onSuccess,
    onError,
    enabled: !!venomProvider && !!walletAddress && enabled,
    retry: false,
    staleTime: Infinity,
    refetchOnReconnect: "always",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  }) as UseQueryResult<NftMetadata[], Error>

  return result
}
