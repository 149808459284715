import { Outlet } from "react-router-dom"

import { UiProvider } from "@/providers/ui-provider"
import { Footer } from "@/components/layout/footer"
import { Header } from "@/components/layout/header"

const RootLayout = () => {
  return (
    <UiProvider>
      <div className="flex min-h-screen flex-col">
        <Header />

        <main className="pb-24">
          <Outlet />
        </main>

        <div className="mt-auto">
          <Footer />
        </div>
      </div>
    </UiProvider>
  )
}
RootLayout.displayName = "RootLayout"

export { RootLayout }
