import React, { PropsWithChildren, useEffect, useRef } from "react"
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from "framer-motion"

import { cn } from "@/utils/tw-utils"

interface ParallaxEffectProps extends PropsWithChildren {
  className?: string
}

export const ParallaxEffect = ({
  children,
  className,
}: ParallaxEffectProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const x = useMotionValue(0)
  const y = useMotionValue(0)

  const xSpring = useSpring(x, {
    stiffness: 5,
    damping: 10,
  })

  const ySpring = useSpring(y, {
    stiffness: 5,
    damping: 10,
  })

  const transform = useMotionTemplate`translateX(${xSpring}px) translateY(${ySpring}px) scale(1.025)`

  const handleMouseMove = (event: MouseEvent) => {
    if (!ref.current) return [0, 0]

    const rect = window.document.body.getBoundingClientRect()

    const width = rect.width
    const height = rect.height

    const mouseX = event.clientX - rect.left
    const mouseY = event.clientY - rect.top

    const rX = -(mouseX - width / 2) * 0.02
    const rY = -(mouseY - height / 2) * 0.02

    x.set(rX)
    y.set(rY)
  }

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove)

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <motion.div
      ref={ref}
      style={{
        transform,
      }}
      className={cn(className)}
    >
      {children}
    </motion.div>
  )
}
