import { PropsWithChildren } from "react"

import { NftMetadata } from "@/types/nft"

import { cn } from "@/utils/tw-utils"
import { Spinner } from "@/components/ui/spinner"

interface PackageNftCardProps extends PropsWithChildren {
  metadata: NftMetadata
  loading?: boolean
  className?: string
}

export const PackageNftCard = ({
  metadata,
  loading,
  className,
}: PackageNftCardProps) => {
  return (
    <div
      className={cn(className, "relative border-[2px] border-border bg-border")}
    >
      <div
        className="aspect-square bg-background"
        style={{
          transform: "translateZ(25px)",
        }}
      >
        <img
          src={metadata.preview.source}
          alt={metadata.name}
          className={cn("block w-full transition-opacity", {
            "opacity-60": loading,
          })}
        />
      </div>

      <div
        className="flex items-center justify-between p-2 px-3 text-background"
        style={{
          transform: "translateZ(0px)",
        }}
      >
        <h6 className="text-center font-display text-secondary">
          {metadata.name}
        </h6>
      </div>

      {loading && (
        <div
          className="absolute left-1/2 top-1/2 z-10"
          style={{
            transform: "translateZ(75px) translateX(-50%) translateY(-50%)",
          }}
        >
          <span className="animate-in fade-in-0">
            <Spinner className="h-8 w-8 text-secondary" />
          </span>
        </div>
      )}
    </div>
  )
}
