import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

type UiProviderProps = {
  children: React.ReactNode
}

type UiProviderState = {
  isUiPackagesDialogActive: boolean
  toggleUiPackagesDialog: (open?: boolean) => void
}

const initialState: UiProviderState = {
  isUiPackagesDialogActive: false,
  toggleUiPackagesDialog: () => {},
}

const UiProviderContext = createContext<UiProviderState>(initialState)

export function UiProvider({ children, ...props }: UiProviderProps) {
  const [isUiPackagesDialogActive, setIsOwnedPackagesDialogActive] =
    useState(false)

  const toggleUiPackagesDialog = useCallback((open?: boolean) => {
    setIsOwnedPackagesDialogActive((prev) =>
      open !== undefined ? open : !prev
    )
  }, [])

  const value: UiProviderState = useMemo(
    () => ({
      isUiPackagesDialogActive,
      toggleUiPackagesDialog,
    }),
    [isUiPackagesDialogActive, toggleUiPackagesDialog]
  )

  return (
    <UiProviderContext.Provider {...props} value={value}>
      {children}
    </UiProviderContext.Provider>
  )
}

export const useUi = () => {
  const context = useContext(UiProviderContext)

  if (context === undefined) {
    throw new Error("useUi must be used within a UiProvider")
  }

  return context
}
