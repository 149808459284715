import BigNumber from "bignumber.js"
import { useMutation } from "react-query"

import { getAddress, toNano } from "@/utils/venom-utils"
import { packageCollectionContractConfig } from "@/config/contracts-config"
import { useVenom } from "@/providers/venom-provider"

interface UseMintPackageByTypeProps {
  walletAddress?: string
  onSuccess?: (data: unknown) => void
  onError?: (error: Error) => void
}

interface MintPackageByTypeArgs {
  mintPrice: number
  typeIndex: number
}

export const useMintNftByType = ({
  walletAddress,
  onSuccess,
  onError,
}: UseMintPackageByTypeProps) => {
  const { venomProvider } = useVenom()

  const gasPrice = toNano(1)

  const mutation = useMutation({
    mutationFn: async ({ typeIndex, mintPrice }: MintPackageByTypeArgs) => {
      if (!venomProvider) {
        throw new Error("Venom provider is not available now")
      }

      if (!walletAddress) {
        throw new Error("Wallet address is not provided")
      }

      const totalPrice = BigNumber(mintPrice).plus(gasPrice).toString()

      const result = await venomProvider.sendMessage({
        sender: getAddress(walletAddress),
        recipient: getAddress(packageCollectionContractConfig.address),
        amount: totalPrice,
        payload: {
          abi: JSON.stringify(packageCollectionContractConfig.abi),
          method: "mintNftByType",
          params: {
            _owner: walletAddress,
            _boxTypeIndex: typeIndex,
            _sourceId: 0,
          },
        },
        bounce: true,
      })

      return result
    },
    onSuccess,
    onError,
    retry: false,
  })

  return mutation
}
