import React from "react"
import { motion } from "framer-motion"

import { Button } from "@/components/ui/button"
import { FireflyParticles } from "@/components/ui/firefly-particles"
import { ParallaxEffect } from "@/components/ui/parallax-effect"
import { PackagesStore } from "@/components/packages/package-store"

const HomePage = () => {
  return (
    <>
      <HomePageBackground />

      <div className="container relative my-12">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{
            duration: 2,
            delay: 0.75,
            ease: "easeOut",
          }}
        >
          <div className="flex flex-col items-center text-center">
            <h1 className="mx-auto max-w-[13em] font-display text-3xl leading-none text-secondary sm:text-4xl">
              Enter the Dragonz Land
            </h1>
            <h3 className="mt-1 max-w-[26em] text-lg text-primary">
              Forge your destiny with legendary cards in the realm of Dragonz
              Land. Look for our incredible Heroes!
            </h3>

            <Button
              size="lg"
              className="mt-5 bg-background/50"
              variant="outline"
              asChild
            >
              <a
                href="https://dragonz-land.gitbook.io/dragonz-land/build-your-dragonz-deck"
                target="_blank"
                rel="noreferrer"
              >
                Build your Dragonz Deck
              </a>
            </Button>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{
            opacity: 1,
            scale: 1,
            y: 0,
          }}
          transition={{
            duration: 1,
            delay: 1.5,
            ease: "easeOut",
          }}
          className="mt-12"
        >
          <PackagesStore />
        </motion.div>
      </div>
    </>
  )
}

const HomePageBackground = () => {
  return (
    <div className="fixed bottom-0 left-0 top-0 z-0 flex h-screen w-full">
      <ParallaxEffect className="h-full w-full">
        <motion.div
          initial={{ opacity: 0, scale: 1.15 }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{
            duration: 3.5,
            ease: "easeOut",
          }}
          className="h-full w-full"
        >
          <div className="bg-full h-full w-full bg-[url('https://assets-global.website-files.com/660fba79b44b29670f6080ec/662ba32521bb4cdf90d2b331_BG_cards.webp')] bg-cover bg-center opacity-60" />
        </motion.div>
      </ParallaxEffect>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 1,
          ease: "easeOut",
        }}
      >
        <FireflyParticles />
      </motion.div>
    </div>
  )
}

export { HomePage }
