import { ProviderRpcClient } from "everscale-inpage-provider"
import { EverscaleStandaloneClient } from "everscale-standalone-client"
import { VenomConnect } from "venom-connect"

export const indexBase64 = process.env.REACT_APP_INDEX_BASE_64!
export const zeroAddress =
  "0:0000000000000000000000000000000000000000000000000000000000000000"

const initTheme = "dark" as const

const standaloneFallback = () =>
  EverscaleStandaloneClient.create({
    connection: {
      id: 1,
      type: "jrpc",
      group: process.env.REACT_APP_CONNECTION_GROUP!,
      data: {
        endpoint: process.env.REACT_APP_CONNECTION_ENDPOINT_URL!,
      },
    },
  })

export const initVenomConnect = async () => {
  return new VenomConnect({
    theme: initTheme,
    checkNetworkId: parseInt(
      process.env.REACT_APP_CONNECTION_CHECK_NETWORK_ID!,
      10
    ),
    nTries: 3,
    providersOptions: {
      venomwallet: {
        links: {},
        walletWaysToConnect: [
          {
            package: ProviderRpcClient,
            packageOptions: {
              fallback:
                VenomConnect.getPromise("venomwallet", "extension") ||
                (() => Promise.reject()),
              forceUseFallback: true,
            },
            packageOptionsStandalone: {
              fallback: standaloneFallback,
              forceUseFallback: true,
            },

            id: "extension",
            type: "extension",
          },
        ],
        defaultWalletWaysToConnect: ["mobile", "ios", "android"],
      },
    },
  })
}
