import React from "react"
import ReactDOM from "react-dom/client"

import "@/styles/globals.css"

import { HelmetProvider } from "react-helmet-async"
import { QueryClient, QueryClientProvider } from "react-query"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import { initVenomConnect } from "@/config/venom-config"
import { ThemeProvider } from "@/providers/theme-provider"
import { VenomProvider } from "@/providers/venom-provider"
import { Toaster } from "@/components/ui/toaster"
import { TooltipProvider } from "@/components/ui/tooltip"
import { HomePage } from "@/routes/home-page"
import { NoMatchPage } from "@/routes/nomatch-page"
import { RootLayout } from "@/routes/root-layout"

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,

    children: [
      {
        path: "/",
        element: <HomePage />,
      },
    ],
  },
  {
    path: "*",
    element: <NoMatchPage />,
  },
])

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark">
      <HelmetProvider>
        <TooltipProvider>
          <QueryClientProvider client={queryClient}>
            <VenomProvider initVenomConnect={initVenomConnect}>
              <RouterProvider router={router} />
            </VenomProvider>
          </QueryClientProvider>

          <Toaster />
        </TooltipProvider>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
)
