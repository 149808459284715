import { Package } from "@/types/package"

export const packagesConfig: Package[] = [
  {
    typeIndex: 1,
    name: "Standard Pack",
    description: "Standard package description…",
    preview: {
      source: "/packages/standard-pack.png",
      mimetype: "image/png",
    },
    files: [
      {
        source: "/packages/standard-pack.png",
        mimetype: "image/png",
      },
    ],
    external_url: "https://www.dragonz.land/",
    packageType: "single",
    cardsQuantity: 6,
    mintPrice: parseInt(process.env.REACT_APP_PACKAGE_STANDARD_MINT_PRICE!),
    totalSupply: 100,
  },
  {
    typeIndex: 2,
    name: "Double Pack",
    description: "Double package description…",
    preview: {
      source: "/packages/double-pack.png",
      mimetype: "image/png",
    },
    files: [
      {
        source: "/packages/double-pack.png",
        mimetype: "image/png",
      },
    ],
    external_url: "https://www.dragonz.land/",
    packageType: "double",
    cardsQuantity: 14,
    mintPrice: parseInt(process.env.REACT_APP_PACKAGE_DOUBLE_MINT_PRICE!),
    totalSupply: 100,
  },
]
