import {
  IconBrandDiscord,
  IconBrandTelegram,
  IconBrandX,
} from "@tabler/icons-react"

import { Button } from "@/components/ui/button"

const Footer = () => {
  return (
    <footer className="relative flex w-full flex-col items-center justify-center bg-background/40 backdrop-blur-sm">
      <div className="h-6 w-full bg-[url('https://assets-global.website-files.com/660fba79b44b29670f6080ec/661fdf4cf3d46673b22d63bc_Fotter_line.svg')] bg-center bg-no-repeat"></div>

      <div className="container flex flex-col items-center gap-4 pb-14 pt-8">
        <ul className="flex gap-3">
          <li>
            <Button variant="link" size="sm">
              <a href="https://dragonz.land" target="_blank" rel="noreferrer">
                Home
              </a>
            </Button>
          </li>
          <li>
            <Button variant="link" size="sm">
              <a
                href="https://dragonz-land.gitbook.io/dragonz-land"
                target="_blank"
                rel="noreferrer"
              >
                About
              </a>
            </Button>
          </li>
          <li>
            <Button variant="link" size="sm">
              <a
                href="https://dragonz-land.gitbook.io/dragonz-land/roadmap"
                target="_blank"
                rel="noreferrer"
              >
                Roadmap
              </a>
            </Button>
          </li>
          <li>
            <Button variant="link" size="sm">
              <a
                href="https://dragonz-land.gitbook.io/dragonz-land/tokenomics"
                target="_blank"
                rel="noreferrer"
              >
                Tokenomics
              </a>
            </Button>
          </li>
        </ul>

        <ul className="flex gap-2">
          <li>
            <Button size="icon" variant="ghost" className="text-secondary">
              <a
                href="https://twitter.com/dragonzlandx"
                target="_blank"
                rel="noreferrer"
              >
                <IconBrandX className="h-5 w-5" />
                <span className="sr-only">Twitter</span>
              </a>
            </Button>
          </li>
          <li>
            <Button
              size="icon"
              variant="ghost"
              className="text-secondary"
              asChild
            >
              <a
                href="http://t.me/dragonzland"
                target="_blank"
                rel="noreferrer"
              >
                <IconBrandTelegram className="h-5 w-5" />
                <span className="sr-only">Telegram</span>
              </a>
            </Button>
          </li>
          <li>
            <Button
              size="icon"
              variant="ghost"
              className="text-secondary"
              asChild
            >
              <a
                href="https://discord.com/invite/DragonzLand"
                target="_blank"
                rel="noreferrer"
              >
                <IconBrandDiscord className="h-5 w-5" />
                <span className="sr-only">Discord</span>
              </a>
            </Button>
          </li>
        </ul>

        <span className="mt-3 text-sm text-primary">
          Copyright 2024, Dragonz Land
        </span>
      </div>
    </footer>
  )
}
Footer.displayName = "Footer"

export { Footer }
