import { motion } from "framer-motion"
import { useQueryClient } from "react-query"

import { Package } from "@/types/package"

import { packagesConfig } from "@/config/packages-config"
import { useVenom } from "@/providers/venom-provider"
import { useToast } from "@/hooks/use-toast"
import { useMintNftByType } from "@/hooks/venom/use-mint-nft-by-type"
import { Button } from "@/components/ui/button"
import { PackageCard } from "@/components/packages/package-card"

export const PackagesStore = () => {
  const packages = packagesConfig
  const queryClient = useQueryClient()
  const { account, connect, isConnected } = useVenom()
  const { toast } = useToast()

  const { mutate: mintPackage, isLoading } = useMintNftByType({
    walletAddress: account?.address.toString(),
    onSuccess: () => {
      toast({
        title: "Dragonz Land Pack Minted",
        description:
          "Your pack has been minted and you’re ready to rise into the skies of Dragonz Land!",
      })

      setTimeout(() => {
        queryClient.invalidateQueries("ownedCollectionNfts")
      }, 1000)
    },
  })

  const handlePackageMint = async (pkg: Package) => {
    if (!isConnected) {
      connect()
      return
    }

    mintPackage({
      mintPrice: pkg.mintPrice,
      typeIndex: pkg.typeIndex,
    })
  }

  return (
    <div className="flex flex-col items-center">
      <ul className="grid w-full max-w-3xl grid-cols-1 gap-8 sm:grid-cols-2">
        {packages.map((pkg) => {
          return (
            <li key={pkg.name} className="w-full">
              <PackageCard pkg={pkg} loading={isLoading}>
                <motion.div
                  initial={{
                    opacity: 0,
                    scale: 1.25,
                  }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                  }}
                  transition={{ delay: 2.25, duration: 1, ease: "easeOut" }}
                >
                  <motion.div
                    initial={{
                      scale: 1,
                    }}
                    whileHover={{
                      scale: 1.1,
                    }}
                    transition={{ duration: 1, ease: "easeOut" }}
                  >
                    <Button
                      size="lg"
                      className="flex items-center shadow-xl disabled:text-background/60 disabled:opacity-100"
                      disabled
                    >
                      {isLoading ? "Loading..." : <>Sold out</>}
                    </Button>
                  </motion.div>
                </motion.div>
              </PackageCard>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
