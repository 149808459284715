import indexAbi from "@/abis/index.abi.json"
import nftAbi from "@/abis/nft.abi.json"
import { Address, ProviderRpcClient } from "everscale-inpage-provider"

import { NftMetadata } from "@/types/nft"

import { indexBase64 } from "@/config/venom-config"

export const getNftMetadata = async (
  provider: ProviderRpcClient,
  nftAddress: Address
): Promise<NftMetadata> => {
  const nftContract = new provider.Contract(nftAbi, nftAddress)
  const getJsonAnswer = (await nftContract.methods
    .getJson({ answerId: 0 } as never)
    .call()) as { json: string }
  return JSON.parse(getJsonAnswer.json ?? "{}") as NftMetadata
}

export const getCollectionItems = async (
  provider: ProviderRpcClient,
  nftAddresses: Address[]
): Promise<NftMetadata[]> => {
  return Promise.all(
    nftAddresses.map(
      async (nftAddress) => await getNftMetadata(provider, nftAddress)
    )
  )
}

export const getNftCodeHash = async (
  provider: ProviderRpcClient,
  collectionAddress: Address,
  collectionAbi: any
): Promise<string> => {
  const contract = new provider.Contract(collectionAbi, collectionAddress)
  const { codeHash } = await contract.methods
    // @ts-ignore
    .nftCodeHash({ answerId: 0 } as never)
    .call({ responsible: true })
  return BigInt(codeHash).toString(16)
}

export const getNftAddresses = async (
  provider: ProviderRpcClient,
  codeHash: string
): Promise<Address[] | undefined> => {
  const addresses = await provider?.getAccountsByCodeHash({
    codeHash,
  })

  return addresses?.accounts
}

export const getCollectionNfts = async (
  provider: ProviderRpcClient,
  collectionAddress: string,
  collectionAbi: any
): Promise<NftMetadata[]> => {
  const nftCodeHash = await getNftCodeHash(
    provider,
    new Address(collectionAddress),
    collectionAbi
  )

  if (!nftCodeHash) {
    return []
  }

  const nftAddresses = await getNftAddresses(provider, nftCodeHash)

  if (!nftAddresses || !nftAddresses.length) {
    return []
  }

  const nftMetadatas = await getCollectionItems(provider, nftAddresses)
  return nftMetadatas
}

export const getNftsByIndexes = async (
  provider: ProviderRpcClient,
  indexAddresses: Address[]
): Promise<NftMetadata[]> => {
  const nftAddresses = await Promise.all(
    indexAddresses.map(async (indexAddress) => {
      const indexContract = new provider.Contract(indexAbi, indexAddress)
      const indexInfo = await indexContract.methods
        .getInfo({ answerId: 0 } as never)
        .call()
      // @ts-ignore
      return indexInfo.nft
    })
  )

  return getCollectionItems(provider, nftAddresses)
}

export const saltCode = async (
  provider: ProviderRpcClient,
  ownerAddress: string,
  collectionAddress: string
) => {
  const tvc = await provider.splitTvc(indexBase64)
  if (!tvc.code) throw new Error("tvc code is empty")

  const saltStruct = [
    { name: "collection", type: "address" },
    { name: "owner", type: "address" },
    { name: "type", type: "fixedbytes3" },
  ] as const

  const { code: saltedCode } = await provider.setCodeSalt({
    code: tvc.code,
    salt: {
      structure: saltStruct,
      abiVersion: "2.1",
      data: {
        collection: new Address(collectionAddress),
        owner: new Address(ownerAddress),
        type: btoa("nft"),
      },
    },
  })

  return saltedCode
}

export const getAddressesFromIndex = async (
  provider: ProviderRpcClient,
  codeHash: string
): Promise<Address[] | undefined> => {
  const addresses = await provider.getAccountsByCodeHash({ codeHash })
  return addresses?.accounts
}

export const getOwnedCollectionNfts = async (
  provider: ProviderRpcClient,
  walletAddress: string,
  collectionAddress: string
) => {
  const saltedCode = await saltCode(provider, walletAddress, collectionAddress)
  const codeHash = await provider.getBocHash(saltedCode)

  if (!codeHash) {
    return
  }

  const indexesAddresses = await getAddressesFromIndex(provider, codeHash)
  if (!indexesAddresses) {
    return
  }

  if (!indexesAddresses || !indexesAddresses.length) {
    if (indexesAddresses && !indexesAddresses.length) return []
    return
  }

  const nftMetadatas = await getNftsByIndexes(provider, indexesAddresses)
  return nftMetadatas
}

export const getOwnedCollectionNftsCount = async (
  provider: ProviderRpcClient,
  walletAddress: string,
  collectionAddress: string
) => {
  const saltedCode = await saltCode(provider, walletAddress, collectionAddress)
  const codeHash = await provider.getBocHash(saltedCode)

  if (!codeHash) {
    return 0
  }

  const indexesAddresses = await getAddressesFromIndex(provider, codeHash)
  if (!indexesAddresses) {
    return 0
  }

  return indexesAddresses?.length ?? 0
}
