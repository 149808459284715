import { packageCollectionContractConfig } from "@/config/contracts-config"
import { useUi } from "@/providers/ui-provider"
import { useVenom } from "@/providers/venom-provider"
import { useOwnedCollectionNfts } from "@/hooks/venom/use-owned-collection-nfts"
import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog"
import { EmptyState } from "@/components/ui/empty-state"
import { Spinner } from "@/components/ui/spinner"
import { PackagesList } from "@/components/packages/packages-list"

export const AccountPackagesDialog = () => {
  const { account } = useVenom()
  const { isUiPackagesDialogActive, toggleUiPackagesDialog } = useUi()

  const { data: packages, isLoading } = useOwnedCollectionNfts({
    collectionAddress: packageCollectionContractConfig.address,
    walletAddress: account?.address,
  })

  return (
    <Dialog
      open={isUiPackagesDialogActive}
      onOpenChange={toggleUiPackagesDialog}
    >
      <DialogContent className=" max-h-[calc(100vh)] max-w-xl overflow-auto">
        <DialogHeader className="mb-3">
          <h3 className="font-display text-xl">
            My packages{packages?.length ? ` (${packages.length})` : ""}
          </h3>
        </DialogHeader>

        {isLoading ? (
          <div className="flex h-40 w-full items-center justify-center text-secondary">
            <Spinner />
          </div>
        ) : packages && packages.length ? (
          <PackagesList packages={packages} />
        ) : (
          <EmptyState
            className="h-40 text-muted-foreground"
            text="No packages yet"
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
