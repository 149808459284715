import React, { PropsWithChildren, useRef } from "react"
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from "framer-motion"

import { cn } from "@/utils/tw-utils"

const ROTATION_RANGE = 25
const HALF_ROTATION_RANGE = 25 / 2

interface TiltEffectProps extends PropsWithChildren {
  className?: string
}

export const TiltEffect = ({ children, className }: TiltEffectProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const x = useMotionValue(0)
  const y = useMotionValue(0)

  const xSpring = useSpring(x, {
    stiffness: 20,
    damping: 10,
  })
  const ySpring = useSpring(y, {
    stiffness: 20,
    damping: 10,
  })

  const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`

  const handleMouseMove = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (!ref.current) return [0, 0]

    const rect = ref.current.getBoundingClientRect()

    const width = rect.width
    const height = rect.height

    const mouseX = (event.clientX - rect.left) * ROTATION_RANGE
    const mouseY = (event.clientY - rect.top) * ROTATION_RANGE

    const rX = mouseY / height - HALF_ROTATION_RANGE
    const rY = (mouseX / width - HALF_ROTATION_RANGE) * -1

    x.set(rX)
    y.set(rY)
  }

  const handleMouseLeave = () => {
    x.set(0)
    y.set(0)
  }

  return (
    <motion.div
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        transformStyle: "preserve-3d",
        transform,
      }}
      className={cn(className, "translate-z-[20px] perspective-[1000px]")}
    >
      {children}
    </motion.div>
  )
}
